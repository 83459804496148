import {INotification} from "../../Notification/types";
import {useNotificationContext} from "../../../../infrastructure/services/notifications/NotificationService";
import React from "react";
import {find, map} from "lodash";
import dayjs from "dayjs";
import {useModuleItems} from "./useModuleItems";

export function useNotificationItems(): [items: INotification[], dismiss: (id: string) => void, seen: (id: string) => void, system?: string] {
	const {notifications, dismiss, seen} = useNotificationContext();

	const moduleItems = useModuleItems();

	const items = React.useMemo<INotification[]>(() => map(notifications, n => ({
		// map group name from module names if some match code / name from module list and us its name
		group: find(moduleItems, m => m.code === n.source)?.name ?? n.source,
		id: n.id,
		dismissed: n.read,
		// convert created date to dayjs
		created: dayjs(n.created),
		recent: n.recent,
		description: n.description,
		linkText: n.linkText,
		linkUrl: n.linkUrl,
		progressPercentage: n.progressPercentage,
		progressText: n.progressText,
		title: n.title
	})), [notifications, moduleItems]);

	return [items, dismiss, seen, find(moduleItems, a => a.active)?.name];
}
