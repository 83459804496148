import React from "react";
import {Button, ButtonVariants} from "@skodaflow/web-library";

type Props = {
    text: string;
    url: string;
    compact?: boolean;
}

export function ExternalNavigationLinkButton({ text, url, compact }: Props) {
	return (
		<a href={url} target="_blank">
			<Button compact={compact} variant={ButtonVariants.GHOST} icon='globe' label={text} />
		</a>
	)
}
