import {IModuleConfiguration} from "../../@marvin3/types/src";
import {useContext} from "react";
import {ApplicationContext} from "../contexts/contexts";
import {useRouteMatch} from "react-router-dom";
import {find} from "lodash";

export function useActiveModule(): IModuleConfiguration | undefined {
	const {marvinConfiguration: {modules}} = useContext(ApplicationContext);

	const route = "/:module?";

	const params = useRouteMatch(route)?.params as { module?: string };

	if(!params?.module)
		return;

	return find(modules, m => m.code.toLowerCase() == params.module?.toLowerCase());
}
