import {ApplicationContext} from "../../../infrastructure/contexts/contexts";
import {AppBar, Box, Toolbar} from "@mui/material";
import {ModulesButton} from "./ModulesButton";
import {ProfileButton} from "./ProfileButton";
import {NotificationButton} from "./NotificationButton";
import {Logo} from "../Logo/Logo";
import {LogoutButton} from "./LogoutButton";
import {LanguageButton} from "./LanguageButton";
import {useContext} from "react";
import {ModuleNode} from "../../Module/Node";

export function Header(): JSX.Element {
	const { marvinConfiguration: { principal } } = useContext(ApplicationContext);

	return (
		<AppBar
			position='relative'
			sx={{
				backgroundColor: "white",
				boxShadow: "none",
				borderBottom: "1px solid #E4E4E4",
				height: "64px",
				color: "black"
			}}
		>
			<Toolbar>
				<Logo home="/"/>
				<Box sx={{
					display: "flex", marginRight: "16px", marginLeft: "16px", flexGrow: 1, alignItems: "center",
					"a,a.active,a:active,a:hover,a:visited": {
						textDecoration: "none",
						color: "inherit"
					}
				}}>
					<ModuleNode node="menu"/>
				</Box>
				<Box sx={{display: "flex", alignItems: "center"}}>
					<ModuleNode node="header"/>
					{principal && <NotificationButton/>}
					{principal && <ProfileButton/>}
					<LanguageButton />
					{principal && <LogoutButton />}
					{principal && <ModulesButton/>}
				</Box>
			</Toolbar>
		</AppBar>
	)
}
