import styled from "@emotion/styled";
import {Icon} from "@skodaflow/web-library";

export const Header = styled.th`
  background-color: #EDF0F2;
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  border: none;
`
