import {LanguagesState} from "../components/Languages";
import {setCookie} from "utils/setCookie";

export type GetStoredLanguages = Partial<LanguagesState> & { modules: LanguagesState["modules"] };

const languageRegExp = /^language\./; // Regular expression to match cookies starting with "language."

const coreLanguageName = "@core";

/**
 * Gets stored languages from cookies
 */
export function getStoredLanguages(): GetStoredLanguages {
	const cookies = document.cookie.split(";");

	const languages: GetStoredLanguages = { modules: {} };

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i].trim();

		if (!languageRegExp.test(cookie))
			continue;

		const cookieParts = cookie.split("=");
		const name = cookieParts[0].replace(languageRegExp, "");
		const value = cookieParts[1];

		// set stored core language
		if(name === coreLanguageName) {
			languages.core = value;
			continue;
		}

		// set stored module language
		languages.modules[name] = value;
	}

	return languages;
}

export function storeLanguage(value: string, name?: string) {
	setCookie(`language.${name ?? coreLanguageName}`, value, 365 * 24 * 60 * 60 * 1000); // 1 rok
}


