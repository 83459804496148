import React from "react";
import * as SignalR from "@microsoft/signalr";
import { Headers } from "./types";

class SignalRHttpClient extends SignalR.HttpClient {
	headers?: Headers;

	constructor(headers?: Headers) {
		super();
		this.headers = headers;
	}

	public async send(request: SignalR.HttpRequest): Promise<SignalR.HttpResponse> {
		if (!request.url) {
			return new SignalR.HttpResponse(500, "Error", "Signal R address not exists.");
		}

		const init: RequestInit = {
			method: request.method,
			credentials: "include",
			headers: {...request.headers, ...this.headers},
			body: request.content,
		};

		return await fetch(request.url, init).then(async (response: Response) => {
			const content: string = await response.text();
			return new SignalR.HttpResponse(response.status, response.statusText, content);
		});
	}
}

export function useSignal<T>(serviceUrl: string, headers: Headers | undefined, methodName: string, callback: (message: T) => void) {
	const connection = React.useMemo(() => new SignalR.HubConnectionBuilder()
		.withUrl(`${serviceUrl}/Notifications`, {
			transport: SignalR.HttpTransportType.LongPolling,
			httpClient: new SignalRHttpClient(headers),
		})
		.build()
	, [headers, serviceUrl]);

	React.useEffect(() => {
		connection?.on(methodName, callback);
		connection?.start();

		return () => {
			connection?.stop();
		};
	}, [connection]);
}
