import {FetchServiceOptions} from "../types";

function addHeaders(target: Headers, input: Headers | Record<string, string> | undefined) {
    if (input instanceof Headers) {
        //@ts-ignore
        input.forEach((value, name) => {
            target.append(name, value);
        });
        return;
    }

    if (input) {
        for (const name of Object.keys(input)) {
            target.append(name, input[name]);
        }
    }
}

/**
 * produces new request init object with default values and values from the input
 */
export function processRequest(input: RequestInfo | URL, init?: RequestInit, options?: FetchServiceOptions) {
    // Create a new RequestInit object to avoid mutation of the original init.
    const newInit: RequestInit = {...init};

    // copy credentials from input or init to newInit
    if (!(typeof input === "object" && "credentials" in input) && !(init && "credentials" in init)) {
        newInit.credentials = "include";
    }

    // Create headers object to combine headers from input and init.
    const headers = new Headers();

    // Add headers from input to the new headers object.
    if (typeof input === "object" && "headers" in input) {
        addHeaders(headers, input.headers);
    }

    // Add headers from init to the new headers object.
    if (init && "headers" in init) {
        addHeaders(headers, new Headers(init.headers));
    }

    headers.set("User-Agent", "Marvin Client");
    headers.set("Marvin3-Language", options?.language ?? "en-GB");

    // Set the headers in the newInit object.
    newInit.headers = headers;

    return newInit;
}
