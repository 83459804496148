import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {Button, Stack} from "@mui/material";
import {ProblemDetails} from "../../@marvin3/types/src/features/api";
import {Typography} from "@skodaflow/web-library";
import {EmailButton} from "./EmailButton";
import {TranslatedText} from "./TranslatedText/TranslatedText";
import {Pre} from "./Pre";
import {EnvironmentName} from "../../EnvironmentName";

type Props = {
    error: ProblemDetails;
	hideDetails?: boolean;
}

export function ProblemDetail({error, hideDetails}: Props) {
	const { $t } = useIntl();

	const { push } = useHistory();

	return (
		<Stack sx={{ textAlign: "center", marginTop: "2rem" }} gap={6}>
			{error.status && (
				<Typography variant="h1" sx={{ fontSize: "4rem !important", margin: "1rem" }}>
					{error.status}
				</Typography>
			)}

			{error.title && (
				<Typography variant="h3">
					{$t({ id: error.title, defaultMessage: error.title })}
				</Typography>
			)}

			<Typography variant="body">
				{error.detail ? $t({ id: error.detail, defaultMessage: error.detail }) : <TranslatedText text="applicationError" />}
			</Typography>

			<Stack direction="row" alignItems="end" gap={2} style={{ flex: 1, justifyContent: "center" }}>
				<Button onClick={() => push("/")}><TranslatedText text="goToHomepage"/></Button>
				<EmailButton mail={$t({ id: "mail" })} color="secondary"><TranslatedText text="contactUs"/></EmailButton>
			</Stack>

			{!hideDetails && configuration.environmentName !== EnvironmentName.Prod && (
				<Pre>
					{JSON.stringify(error, null, 2)}
				</Pre>
			)}
		</Stack>
	)
}
