import React from "react"
import {filter, map} from "lodash";
import {useSignal} from "./useSignal";
import {fetchService} from "../fetchService";
import {stringify} from "query-string";
import {Headers, Notification} from "./types";

type State = Notification[];

const initialState: State = [];

type SeedNotifications = {
    type: "seed",
    payload: Notification[]
}

type ReceiveNotification = {
    type: "receive",
    payload: Notification
}

type RemoveNotification = {
    type: "remove",
    payload: string;
}

type SeenNotification = {
    type: "seen",
    payload: string;
}

type Action = SeedNotifications | ReceiveNotification | RemoveNotification | SeenNotification;

const reducer = (prevState: State, action: Action): State => {
	switch (action.type) {
		// replace notifications with action notifications
		case "seed":
			return map(action.payload, notification => ({...notification, recent: false}));
			// receive action adds new notification at beginning of notifications
		case "receive":
			return [{
				...action.payload,
				recent: action.payload.read
			}, ...filter(prevState, n => n.id !== action.payload.id)]
		case "remove":
			return filter(prevState, n => n.id !== action.payload)
		case "seen": {
			return map(prevState, notification => {
				if (notification.id === action.payload)
					return {...notification, recent: false}
				return notification;
			});
		}
		default:
			throw new Error("Unsupported action type");
	}
};

/**
 * notifications hook handles connection to Signal R and holds state of current notifications
 */
export function useNotifications(serviceUrl: string, headers?: Headers): [notifications: State, dismiss: (id: string) => void, select: (id: string) => void] {
	const [state, dispatch] = React.useReducer(reducer, initialState);

	// save message on update
	useSignal(serviceUrl, headers, "MessageReceived", (payload: Notification) => {
		dispatch({type: "receive", payload})
	});

	// on mount retrieve current data from backend
	React.useEffect(() => {
		fetchService.get(`${serviceUrl}/Message?${stringify({top: 100, justUnread: true})}`)
			.then(response => dispatch({type: "seed", payload: response as Notification[]}));
	}, []);

	const dismiss = React.useCallback((id: string) => {
		fetchService.post(`${serviceUrl}/Message/MarkAsRead?messageId=${id}`)
			.then(() => dispatch({type: "remove", payload: id}))
	}, [])

	const select = React.useCallback((payload: string) => {
		dispatch({ type: "seen", payload })
	}, [])

	return [state, dismiss, select];
}
