import React from "react";
import {ApplicationContext} from "../../../../infrastructure/contexts/contexts";
import {find} from "lodash";
import {IClaim} from "../../../../@marvin3/types/src";

export function useUserName(): [lastname: string, firstname: string] {
	const {marvinConfiguration: { principal }} = React.useContext(ApplicationContext);

	return React.useMemo(() => ([
		find(principal?.claims, (claim: IClaim) => claim.type === "sn")?.value ?? "",
		find(principal?.claims, (claim: IClaim) => claim.type === "givenName")?.value ?? ""
	]), [principal?.claims]);
}
