import {useDocumentEvent} from "../../events";
import {DependencyList, useCallback, useState} from "react";
import {ApiErrorEvent, ProblemDetails} from "../types";

/**
 * hook attached to the unauthenticated event
 */
export function useApiErrorEvent(onApiError: (error: CustomEvent<ProblemDetails>) => void, deps: DependencyList) {
	useDocumentEvent(ApiErrorEvent, onApiError as any, deps);
}

/**
 * tracks api event errors locally in state
 */
export function useApiErrors(): [errors: ProblemDetails[], dismiss: (...errors: ProblemDetails[]) => void] {
	const [state, setState] = useState([] as ProblemDetails[]);

	useApiErrorEvent((event) => {
		setState(prevState => [...prevState, event.detail]);
	}, [])

	const dismiss = useCallback((...errors: ProblemDetails[]) => {
		setState(prevState => prevState.filter(error => !errors.includes(error)));
	}, []);

	return [state, dismiss];
}
