import React from "react";
import {Container} from "@mui/material";
import cn from "classnames";
import {Icon} from "@skodaflow/web-library";
import tokens from "@skodaflow/web-tokens/src/tokens.json"
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

const transition = "200ms linear";

const backgroundColorTransition = {
	"-webkit-transition": `background-color ${transition}`,
	"-ms-transition": `background-color ${transition}`,
	"transition": `background-color ${transition}`
}

const opacityTransition = {
	"-webkit-transition": `opacity ${transition}`,
	"-ms-transition": `opacity ${transition}`,
	"transition": `opacity ${transition}`
}

type Props = React.PropsWithChildren<{
    onSelect?: () => void;
    selected?: boolean;
    highlighted?: boolean;
    onDismiss?: () => void;
    sx?: SxProps<Theme>;
}>

export function NotificationItemContainer({onSelect, onDismiss, selected, children, sx, highlighted}: Props) {
	return (
		<Container
			className={cn("notification-item", {"notification-item-selected": selected})}
			onClick={onSelect}
			sx={{
				position: "relative",
				padding: "24px 32px 24px 40px",
				cursor: selected ? "default" : "pointer",
				backgroundColor: highlighted ? tokens["flow-colors"]["surface-primary"] : tokens["flow-colors"]["background-primary"],
				...backgroundColorTransition,
				":hover": {
					backgroundColor: tokens["flow-colors"]["surface-primary"],
					...backgroundColorTransition
				},
				".ballotx": {
					opacity: selected ? 100 : 0,
					position: "absolute",
					right: "10px",
					top: "15px",
					padding: "8px",
					fontSize: "16px",
					cursor: "pointer",
					...opacityTransition
				},
				":hover > .ballotx, .notification-item-selected": {
					opacity: 100,
					...opacityTransition
				},
				...sx
			}}
		>
			{onDismiss && (
				<Icon
					className='ballotx'
					onClick={onDismiss}
				/>
			)}

			{children}
		</Container>
	)
}
