import {ApiErrorEvent, ProblemDetails} from "../types";

/**
 * dispatches new document event with name of UnauthenticatedEvent, signaling that user became not authenticated
 */
export function dispatchApiErrorEvent(error: ProblemDetails) {
	document.dispatchEvent(new CustomEvent(ApiErrorEvent, {
		bubbles: true,
		cancelable: false,
		composed: true,
		detail: error
	}));
}
