import {IModuleContext} from "../../@marvin3/types/src";
import {fetchService} from "../../infrastructure/services/fetchService";
import {loadAssets, deactivateModuleStyles} from "./utils";

export function unmountModule(code: string) {
	//@ts-ignore
	const componentUnmount = window[`unmount${code}`];
	if (componentUnmount)
		componentUnmount(`${code}`);


	deactivateModuleStyles(code)
	// todo remove styles and script
	// 		- be aware of stupid lifecycle in case of language change, as it forces unload and load of module again
}

/**
 * original function that loads and registers module script and styles
 */
export async function mountModule(code: string, context: IModuleContext, isCancelled: () => boolean) {
	// todo refactor (potential problems with multiple modules if one of them is not loaded yet and we are trying to load another one)
	window.registerModule = function (renderFunction: Function, unmountFunction: Function) {
		(window as any)[`render${code}`] = renderFunction;
		(window as any)[`unmount${code}`] = unmountFunction;
	};

	function render() {
		//@ts-ignore
		window[`render${code}`](`${code}`, { ...context });
	}

	// remove trailing slash
	const hostUrl = context.moduleConfiguration.clientUrl.replace(/\/+$/, "");

	const manifestData: any = await fetchService.get(`${hostUrl}/asset-manifest.json`);

	if(isCancelled())
		return;

	// load assets from host
	loadAssets(code, hostUrl, manifestData, render)
}
