import React, {useContext} from "react";
import {Notification, Headers} from "./types";
import {useNotifications} from "./useNotifications";
import {ApplicationContext} from "../../contexts/contexts";

type NotificationContext = {
    notifications: Notification[];
    dismiss: (id: string) => void;
    seen: (id: string) => void;
}

const Context = React.createContext<NotificationContext>({
	notifications: [],
	dismiss: () =>  {
		throw new Error("Not defined")
	},
	seen: () => {
		throw new Error("Not defined")
	}
})

export function useNotificationContext(): NotificationContext{
	return React.useContext<NotificationContext>(Context);
}

type Props = React.PropsWithChildren<{
    serviceUrl: string;
    headers?: Headers
}>

function Notifications({children, serviceUrl, headers}: Props){
	const [notifications, dismiss, seen] = useNotifications(serviceUrl, headers);

	// pack context value to memoized object
	const value = React.useMemo(() => ({ notifications, dismiss, seen }), [notifications, dismiss, seen]);

	return (
		<Context.Provider value={value}>
			{children}
		</Context.Provider>
	)
}

export function NotificationService(props: Props){
	const { marvinConfiguration: { principal } } = useContext(ApplicationContext);

	if(!principal)
		return <>{props.children}</>;

	return <Notifications {...props} />
}
