import { Icon } from "@skodaflow/web-library";
import { useIntl } from "react-intl";
import { IconButton, Tooltip } from "@mui/material";
import { logOutUser } from "../../../infrastructure/services/userService";

export function LogoutButton() {
	const intl = useIntl();
	const text = intl.formatMessage({ id: "signOut" });

	return (
		<Tooltip title={text}>
			<IconButton aria-label={text} onClick={logOutUser}>
				<Icon name="logout" fontSize="small" />
			</IconButton>
		</Tooltip>
	);
}
