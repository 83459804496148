import {IModuleConfiguration} from "@marvin3/types/src";
import {HomepageView} from "components/views/HomepageView";
import {ApplicationContext, IApplicationContext} from "infrastructure/contexts/contexts";
import React from "react";
import {Route, Switch} from "react-router-dom";
import {map} from "lodash";
import {ModuleLoader} from "./components/Module/Loader";
import {AuthenticationElevator} from "./components/Redirector/Redirect";

export default function Routes() {
	const {marvinConfiguration: {modules, principal}} = React.useContext<IApplicationContext>(ApplicationContext);

	return (
		<Switch>
			{principal && map(modules, (module: IModuleConfiguration) => {
				const key = module.code.toLowerCase();

				return (
					<Route
						key={key}
						path={`/${key}`}
						render={({history}) => (
							module.authModuleInfo
								? <AuthenticationElevator module={module} />
								: <ModuleLoader history={history} code={module.code}/>
						)}
					/>
				);
			})}

			<Route path="/" component={HomepageView}/>
		</Switch>
	);
}
