import {PropsWithChildren, useMemo, useState} from "react";
import {ApplicationContext, defaultApplicationContext, IApplicationContext} from "./contexts";
import {IMarvinConfiguration, IModulePartsRenderer} from "../../@marvin3/types/src";
import {useQuery} from "@tanstack/react-query";
import {fetchJson, ProblemDetails} from "../../@marvin3/types/src/features/api";

function useConfiguration() {
	const key = `${configuration.coreServiceUrl}/Configuration`;

	return useQuery<IMarvinConfiguration, ProblemDetails>(
		[key],
		() => fetchJson<IMarvinConfiguration>(key, undefined, {omitProblems: true})
	);
}

/**
 * prepares application context using wrapped (and loaded) marvin configuration and default application context
 * @param props
 * @constructor
 */
export function AppContext(props: PropsWithChildren<{}>): JSX.Element {
	const { data, isFetching, error } = useConfiguration();

	const [modules, setModules] = useState<IApplicationContext["modules"]>({})

	const applicationContext = useMemo<IApplicationContext>(() => ({
		...defaultApplicationContext,

		// modules stuff
		modules,
		onModuleLoad: (data: IModulePartsRenderer) => {
			if(!data.moduleCode)
				return;

			setModules((prev) => ({
				...prev,
				[data.moduleCode!]: data
			}));
		},


		// use loaded or default configuration and map app states
		marvinConfiguration: data ?? defaultApplicationContext.marvinConfiguration,
		loading: isFetching,
		error,

	}), [data, isFetching, error, modules])

	return (
		<ApplicationContext.Provider value={applicationContext}>
			{props.children}
		</ApplicationContext.Provider>
	);
}
