import cz from "./cs-CZ.json";
import en from "./en-GB.json";
import {capitalize} from "lodash";

export function getTranslations(code: string): { [key: string]: string } {
	switch (code) {
		case "cs-CZ":
			return cz;
		case "en-GB":
			return en;
		default:
			return en;
	}
}

/**
 * Resolves display name for given culture code (e.g. "en-GB" -> "English")
 * @param culture
 */
export function getCultureName(culture: string) {
	const language = culture.split("-")[0];
	const displayNames = new Intl.DisplayNames(language, { type: "language" });
	return capitalize(displayNames.of(language)) ?? culture;
}
