import {PropsWithChildren, useCallback, useMemo} from "react";
import {useApiErrors} from "../../@marvin3/types/src/features/api";
import {UnauthorizedPopup} from "../Authentication/UnauthorizedPopup/UnauthorizedPopup";
import {filter, size} from "lodash";

/**
 * Error boundary component that handles unauthenticated api errors, should be extended in future with functionality
 * of Legacy Error Boundary
 * @constructor
 */
export function ErrorBoundary({children}: PropsWithChildren<any>) {
	const [apiErrors, dismiss] = useApiErrors();

	const unauthorized = useMemo(() => filter(apiErrors, (e) => e.status === 401), [apiErrors]);

	const dismissUnauthorized = useCallback(() => dismiss(...unauthorized), [unauthorized, dismiss]);
	return (
		<>
			<UnauthorizedPopup onClose={dismissUnauthorized} open={size(unauthorized) > 0} />
			{children}
		</>
	)
}


// https://testportal.skoda-auto.com/pkmslogout
