import {Box} from "@mui/material";
import {StyledFooter, StyledLink} from "./styled";
import {TranslatedText} from "../ui/TranslatedText/TranslatedText";

function Footer() {

	return (
		<StyledFooter>
			<Box className="subheadline" display='flex'>
				<StyledLink target="_blank" href="https://wiki.skoda.vwgroup.com/display/KRE/Introduction+to+MARVIN+3">
					<TranslatedText text="wiki" />
				</StyledLink>
			</Box>
			<Box className="subheadline" display='flex'>
				<StyledLink target="_blank" href="https://portal.skoda-auto.com">
					<TranslatedText text="registration" />
				</StyledLink>
			</Box>
			<Box display='flex' flex={8} flexDirection="row-reverse">
				<TranslatedText text="footer" />
			</Box>
		</StyledFooter>
	)
}


export { Footer }
