interface Manifest {
  files: {
    "main.js": string;
    "main.css": string;
  };
}

export function getAssetId(asset: "script" | "style", code: string): string {
	return `micro-frontend-${asset}-${code}`;
}

export function loadScriptAsset(code: string, src: string): Promise<unknown> {
	if(hasAssetElement("script", code))
		return Promise.resolve()

	return new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.id = getAssetId("script", code);
		script.src = src;
		script.type = "module";
		script.crossOrigin = "";
		script.onload = resolve;
		script.onerror = reject;
		document.head.appendChild(script);
	});
}

export function loadStyleAsset(code: string, href: string): Promise<unknown> {
	if(hasAssetElement("style", code)){
		activateStyleElement(code)
		return Promise.resolve();
	}

	return new Promise((resolve, reject) => {
		const style = document.createElement("link");
		style.id = getAssetId("style", code);
		style.href = href;
		style.type = "text/css";
		style.rel = "stylesheet";
		style.onload = resolve;
		style.onerror = reject;
		document.head.appendChild(style);
	});
}

export function loadAssets(code: string, hostUrl: string, manifest: Manifest, onload: () => void): void {
	Promise.all([
		loadScriptAsset(code, `${hostUrl}${manifest.files["main.js"]}`),
		manifest.files["main.css"] ? loadStyleAsset(code, `${hostUrl}${manifest.files["main.css"]}`) : Promise.resolve()
	])
		.then(onload)
		.catch((errorEvent) => {
			// eslint-disable-next-line no-console
			console.error("Failed to load script or style: ", errorEvent);
		});
}

function hasAssetElement(type: "script" | "style", code: string): boolean {
	return !!document.getElementById(getAssetId(type, code))
}

function disableStyleElement(code: string, disabled: boolean): void {
	const stylesheet = document.getElementById(getAssetId("style", code)) as HTMLLinkElement | null;
	if(stylesheet)
		stylesheet.disabled = disabled;
}
function activateStyleElement(code: string): void {
	disableStyleElement(code, false)
}
export function deactivateModuleStyles(code: string): void {
	disableStyleElement(code, true)
}
