import {Container, Button} from "@mui/material";
import {useIntl} from "react-intl";
import {useCallback, useContext} from "react";
import {Route, useHistory} from "react-router-dom";
import {ApplicationContext} from "../../infrastructure/contexts/contexts";
import {Outlined} from "../Container/Outlined";
import {Icon} from "@skodaflow/web-library";
import {ProblemDetail} from "../ui/ProblemDetail";
import {AuthModuleInfo, IModuleConfiguration} from "../../@marvin3/types/src";
import {saveRedirectWhenReachable} from "../Redirector/Redirect";

export function ModuleNotFound() {
	const intl = useIntl();

	return (
		<ProblemDetail
			error={{
				title: intl.formatMessage({ id: "notFound"}),
				detail: intl.formatMessage({ id: "moduleNotFound" }),
				status: 404,
				type: "https://httpstatuses.com/404"
			}}
		/>
	)
}


export function getIcon(info?: AuthModuleInfo){
	if(!info)
		return;
	if(info.external)
		return (
			<Icon className='square-expand'></Icon>
		)
	return (
		<Icon className='lock'></Icon>
	)
}

export function useAuthenticationElevate(){
	return useCallback((module: IModuleConfiguration) => {
		if(!module.authModuleInfo)
			return;
		if(!module.authModuleInfo.external)
			saveRedirectWhenReachable(module.code);
		window.location.href = module.authModuleInfo.url
	}, [])
}

export function useModuleOpen() {
	const authenticationElevate = useAuthenticationElevate();

	return useCallback((module: IModuleConfiguration) => {
		const url = `/${module.code.toLowerCase()}/homepage`;

		if (!module.authModuleInfo) {
			// Trigger a hard reload by assigning a new location
			window.location.assign(url);
			return;
		}

		// If authentication is required, handle that
		authenticationElevate(module);
	}, []);
}

function Modules() {
	const intl = useIntl();
	const { marvinConfiguration: { modules } } = useContext(ApplicationContext);
	const open = useModuleOpen();

	return (
		<Container maxWidth="md" disableGutters style={{paddingTop: "2rem"}}>

			<Route path="/:module" component={ModuleNotFound} />

			<Outlined header={intl.formatMessage({ id: "modules" })} direction='column' gap='1rem'>
				{modules.map((module) => (
					<Button
						key={module.code}
						variant='outlined'
						color='secondary'
						fullWidth
						endIcon={getIcon(module.authModuleInfo)}
						onClick={() => open(module)}
					>
						{module.name}
					</Button>
				))}
			</Outlined>
		</Container>
	)
}


export { Modules }
