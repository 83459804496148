import {KeyOf} from "types";
import {ApplicationContext} from "infrastructure/contexts/contexts";
import React, {useContext} from "react";
import {find, get} from "lodash";
import {IModulePartsRenderer} from "../../@marvin3/types/src";
import {useActiveModule} from "infrastructure/hooks/useActiveModule";

type Props = {
    /**
     * node name (header, menu, etc.)
     */
    node: KeyOf<IModulePartsRenderer, JSX.Element | undefined>;

    /**
     * custom render function for the node
     * @param node
     */
    render?: (node: JSX.Element) => JSX.Element;
}

/**
 * Component renders a module node (header, menu, etc.) based on the currently active module
 */
export function ModuleNode({node, render}: Props) {
	const {marvinConfiguration: {principal}, modules} = useContext(ApplicationContext);

	const activeModule = useActiveModule();

	const module = get(find(modules, m => m.moduleCode == activeModule?.code), node);

	if (!principal || !module)
		return null;

	return render ? render(module) : module;
}
