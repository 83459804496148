import { ButtonVariants, Icon, Modal } from "@skodaflow/web-library";
import { useUserName } from "./hooks/useUserName";
import ProfilePage from "../../views/ProfilePage";
import { useSwitch } from "./hooks/useSwitch";
import { useIntl } from "react-intl";
import { IconButton, Tooltip } from "@mui/material";

export function ProfileButton() {
	const intl = useIntl();
	const [last, first] = useUserName();
	const [open, onOpen, onClose] = useSwitch(false);
	const text = intl.formatMessage({ id: "userProfile" });

	return (
		<>
			<Modal
				open={open}
				handleClose={onClose}
				firstButton="Close"
				firstButtonVariant={ButtonVariants.SECONDARY}
				onClickTopButton={onClose}
				size="full-width"
				maxWidth="75%"
			>
				<ProfilePage />
			</Modal>
			<Tooltip title={text}>
				<IconButton aria-label={text} onClick={onOpen}>
					<Icon name="user" fontSize="small" />
				</IconButton>
			</Tooltip>
		</>
	);
}
