import React from "react";
import {BrowserRouter} from "react-router-dom";

// Skoda Flow Design System
import {SkodaThemeProvider} from "@skodaflow/web-library";
import "@skodaflow/web-library-deprecated/theme/skoda/fonts.css"
import "@skodaflow/web-library-deprecated/theme/skoda/globals.css";
import "@skodaflow/web-library-deprecated/theme/skoda/icons.css"

import Router from "./Routes";
import {Header, Workspace} from "components";
import {LegacyErrorBoundary} from "./components/ErrorBoundary/LegacyErrorBoundary";
import {AppContext} from "infrastructure/contexts/AppContext";
import {EnvironmentName} from "./EnvironmentName"
import {NotificationService} from "./infrastructure/services/notifications/NotificationService";
import {EnvironmentBar} from "./@marvin3/types/src";
import {Languages} from "./localization/components/Languages";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ErrorBoundary} from "./components/ErrorBoundary/ErrorBoundary";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			staleTime: 1000 * 60 * 60 * 24,
			cacheTime: 1000 * 60 * 60 * 24,
		}
	}
});

export function App(): JSX.Element {
	return (
		<QueryClientProvider client={queryClient}>
			<SkodaThemeProvider>
				<BrowserRouter>
					<AppContext>
						<Languages>
							<NotificationService serviceUrl={configuration.notificationServiceUrl}>
								<ErrorBoundary>
									<LegacyErrorBoundary>
										<div style={{height: "100vh", display: "flex", flexDirection: "row"}}>
											{configuration.environmentName !== EnvironmentName.Prod && (
												<EnvironmentBar
													environmentName={configuration.environmentBarText}
													backgroundColor={configuration.environmentBarColor}
												/>
											)}
											<div style={{flex: 1, display: "flex", flexDirection: "column"}}>
												<Header/>
												<Workspace>
													<Router/>
												</Workspace>
											</div>
										</div>
									</LegacyErrorBoundary>
								</ErrorBoundary>
							</NotificationService>
						</Languages>
					</AppContext>
				</BrowserRouter>
			</SkodaThemeProvider>
		</QueryClientProvider>
	)
	;
}
