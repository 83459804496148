
export const ApiErrorEvent = "api-error";

/**
 * Common API exception response using ProblemDetails implementation of RFC 7807.
 * Such implementation is used by ASP.NET Core and Marvin modules.
 */
export type ProblemDetails = {
	type: string;
	title?: string;
	status?: number;
	detail?: string;
	instance?: string;
	/**
	 * common error list (mainly used in validation errors)
	 */
	errors?: { [key: string]: string; }
	/**
	 * additional properties that can be added by the API
	 */
	[key: string]: any;
};


export type FetchServiceOptions = {
	/**
	 * language of the request (should be in format of RFC 5646 and is used for localization), e.g. en-US
	 * if not specified, the language will be used as the one recognized by the Marvin Core (module language can differ)
	 */
	language?: string;

	/**
	 * list of problem status codes or resolve function that should be omitted in dispatching of the API error event (e.g. 404)
	 * the problem will still be thrown as an exception but the event won't be dispatched (the handling of the problem is up to the caller)
	 */
	omitProblems?: boolean | number[] | ((response?: Response) => boolean);
}


