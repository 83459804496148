import {ApplicationContext} from "infrastructure/contexts/contexts";
import {Box, Container} from "@mui/material";
import {Redirect, shouldRedirect} from "../Redirector/Redirect";
import {TranslatedText} from "../ui/TranslatedText/TranslatedText";
import {Authenticate} from "../Authenticate/Authenticate";
import {Modules} from "../Modules/Modules";
import {Footer} from "../Footer/Footer";
import {useContext} from "react";
import {LoadingBox} from "../Loading/LoadingBox";
import {ProblemDetail} from "../ui/ProblemDetail";
import {chain} from "lodash";

function useHomepageState() {
	const {marvinConfiguration: {principal}, loading, error, modules} = useContext(ApplicationContext);

	if (loading)
		return "loading";

	if (error?.status === 401)
		return "unauthenticated";

	// if any other error than 401
	if (error)
		return error;

	if (!principal)
		return "unauthenticated";

	if (shouldRedirect(chain(modules).reject("authModuleInfo").map("code").value()))
		return "redirect";

	return "ready";
}


export function HomepageView(): JSX.Element {
	const state = useHomepageState();

	return (
		<LoadingBox loading={state === "loading"}>
			<Container maxWidth="md" disableGutters style={{paddingTop: "2rem"}}>
				<Box display="flex" justifyContent="center">
					<h2><TranslatedText text="marvin3"/></h2>
				</Box>
				{typeof state === "object" && <ProblemDetail error={state}/>}
				{state === "unauthenticated" && <Authenticate/>}
				{state === "redirect" && <Redirect/>}
				{state === "ready" && <Modules/>}
			</Container>
			<Footer/>
		</LoadingBox>
	);
}
