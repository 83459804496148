import React from "react";
import { History } from "history";
export * from "./components";
export { useSwitch } from "./hooks/useSwitch";
export { useOnce } from "./hooks/useOnce";

export * from "./contexts/moduleContext";
export * from "./hooks/useCreateUrl";


// TODO: procistit
// TODO: typy sjednotit s BE

export interface IModuleContext {
	history: History;
	onModuleLoad: (moduleParts: IModulePartsRenderer) => Promise<void> | any;
	getService<TService>(key: string): TService;
	moduleConfiguration: IModuleConfiguration;
	configuration: IConfiguration;
	cultures: IConfigurationCulture[];
	settings: { [key: string]: any };

	/**
	 * @deprecated use SkodaTheme from "@skodaflow/web-library" instead
	 */
	theme: any;

	/**
	 * @deprecated use "@skodaflow/web-tokens/src/tokens.json" instead
	 */
	concernThemeVariables: any;

	/**
	 * @deprecated use MUI sx styles instead
	 */
	isMobile?: boolean;
}

export interface IModulePartsRenderer {
	header?: JSX.Element;
	menu?: JSX.Element;
	navigation?: JSX.Element;
	moduleCode?: string;
}

export interface IConfiguration {
	environmentName: string;
	environmentBarText: string;
	environmentBarColor: string;
	coreServiceUrl: string;
	notificationServiceUrl: string;
	blobStorageServiceUrl: string;
	authenticationServiceUrl: string; // pouziva se pouze v prostredi Development
	b2bPortalUrl: string;
	organizationCodes: { [key: string]: string[] };
	applicationInsightsInstrumentationKey: string;
	b2xLoginUrl: string;
	vwCloudIdpLoginUrl: string;
}

// TODO: ???, ve FetchService.ts je ten enum take (rozsireny o dalsi polozky)
export enum ErrorTypes {
	unauthorized = "unauthorized",
	serverError = "serverError",
}

/**
 * @deprecated use fetch functions from types/features/api
 */
export interface IFetchService {
	// tela vsech odpovedi serveru musi byt ve formatu JSON

	// pokud neni parametr 'headers' zadan, bude vytvoren novy objekt typu Headers
	// pokud v parametru 'headers' neni pritomen header 'User-Agent', doplni se s hodnotou 'Marvin Client'
	// pokud v parametru 'headers' neni pritomen header 'Marvin3-Language', doplni se aktualni jazyk Marvina,
	// pripadne jazyk prohlizece
	// v prostredich 'Development' a 'Emulator' bude do parametru 'headers' pridan header 'Marvin3-Principal'
	// s autentizacnim tokenem
	get<TResult>(url: string, headers?: Headers): Promise<TResult>;

	// pokud neni parametr 'headers' zadan, bude vytvoren novy objekt typu Headers
	// pokud v parametru 'headers' neni pritomen header 'User-Agent', doplni se s hodnotou 'Marvin Client'
	// pokud v parametru 'headers' neni pritomen header 'Marvin3-Language', doplni se aktualni jazyk Marvina,
	// pripadne jazyk prohlizece
	// v prostredich 'Development' a 'Emulator' bude do parametru 'headers' pridan header 'Marvin3-Principal'
	// s autentizacnim tokenem
	// pokud je zadan parametr 'body' a v parametru 'headers' neni pritomen header 'Content-Type', bude header doplnen
	// s hodnotou 'application/json' a body bude odeslano jako retezec ve formatu JSON
	post<TResult>(url: string, body?: any, headers?: Headers): Promise<TResult>;

	// pokud neni parametr 'headers' zadan, bude vytvoren novy objekt typu Headers
	// pokud v parametru 'headers' neni pritomen header 'User-Agent', doplni se s hodnotou 'Marvin Client'
	// pokud v parametru 'headers' neni pritomen header 'Marvin3-Language', doplni se aktualni jazyk Marvina,
	// pripadne jazyk prohlizece
	// v prostredich 'Development' a 'Emulator' bude do parametru 'headers' pridan header 'Marvin3-Principal'
	// s autentizacnim tokenem
	// pokud je zadan parametr 'body' a v parametru 'headers' neni pritomen header 'Content-Type', bude header doplnen
	// s hodnotou 'application/json' a body bude odeslano jako retezec ve formatu JSON
	put<TResult>(url: string, body?: any, headers?: Headers): Promise<TResult>;

	// pokud neni parametr 'headers' zadan, bude vytvoren novy objekt typu Headers
	// pokud v parametru 'headers' neni pritomen header 'User-Agent', doplni se s hodnotou 'Marvin Client'
	// pokud v parametru 'headers' neni pritomen header 'Marvin3-Language', doplni se aktualni jazyk Marvina,
	// pripadne jazyk prohlizece
	// v prostredich 'Development' a 'Emulator' bude do parametru 'headers' pridan header 'Marvin3-Principal'
	// s autentizacnim tokenem
	delete<TResult>(url: string, headers?: Headers): Promise<TResult>;
}

/**
 * @deprecated not used at all
 */
export interface ICssGeneratorService {
	/**
	 * @deprecated not used at all
	 */
	createAndAppendCss(modulePath: string, variables: ICssGeneratorVariables[]): any;
}

export interface INotificationServiceConnectionContext {
	Provider: (props: ISignalrProviderProps) => JSX.Element;
	service(): INotificationService;
	Consumer: React.Consumer<INotificationService>;
}

export interface INotificationService {
	notifications: INotificationItem[];
	alertNotifications: INotificationItem[];
	markMessageAsRead: (id: string) => Promise<void> | any;
	removeFromAlert: (item: INotificationItem) => Promise<void> | any;
}

export interface INotificationItem {
	id: string;
	userName: string;
	source: string;
	created: Date;
	read: boolean;
	title: string;
	description: string;
	linkText: string;
	linkUrl: string;
	progressPercentage?: number;
	progressText?: string;
}

export interface ISignalrProviderProps {
	children?: React.ReactNode | React.ReactNode[];
}

export interface IErrorHandlerServiceContext {
	Provider: (props: any) => JSX.Element;
	Consumer: React.Consumer<IErrorHandlerService>;
}

export interface IErrorHandlerService {
	errors: IError[];
	setError: (item: any) => void;
	removeError: (item?: any) => void;
}

export interface IError {
	message: string;
	id: Date;
	type: ErrorTypes;
}

export interface IClaim {
	type: string;
	value: string;
}

export interface IClientPrincipal {
	claims: IClaim[];
}

export interface IMarvinConfiguration {
	modules: IModuleConfiguration[];
	cultures: IConfigurationCulture[];
	principal?: IClientPrincipal;
}

export interface IModuleConfiguration {
	code: string;
	name: string;
	clientUrl: string;
	serviceUrl: string;
	cultures: string[];
	version: string;
	configurations: { [key: string]: string }[];
	requiredAuthentication: string;
	authModuleInfo?: AuthModuleInfo;
}

export interface AuthModuleInfo {
	external: boolean;
	url: string;
}

// TODO: -> IModuleCulture?, ...typ sjednotit s BE
export interface IConfigurationCulture {
	code: string;
	name: string;
	default?: boolean;
}

/**
 * @deprecated not used at all
 */
export interface ICssGeneratorVariables {
	elementKey: string; // TODO: -> selector?
	cssProperties: ICssProperties[]; // TODO: -> properties?
}

/**
 * @deprecated not used at all
 */
export interface ICssProperties {
	cssProperty: string; // TODO: -> key?
	cssPropertyValue: string; // TODO: -> value?
}

export enum LogLevel {
	Verbose = 0,
	Information = 1,
	Warning = 2,
	Error = 3,
	Critical = 4,
}

// TODO: -> ILoggerService?
export interface ILogger {
	exception(error: Error, logLevel?: LogLevel): void;
	trace(message: string, logLevel?: LogLevel): void;
	error(message: string): void;
	warning(message: string): void;
	information(message: string): void;
}

export class WebApiException extends Error {
	public problemDetails: {};
	public statusCode: number;

	constructor(message: string, problemDetailsJson: string, statusCode: number) {
		super(message);
		Object.setPrototypeOf(this, new.target.prototype);
		this.name = WebApiException.name;

		this.problemDetails = JSON.parse(problemDetailsJson); // TODO: osetrit pripadne chyby
		this.statusCode = statusCode;
	}
}

export class UnauthorizedException extends WebApiException {
	constructor(problemDetailsJson: string, statusCode: number) {
		super("Unauthorized.", problemDetailsJson, statusCode); // TODO: resource string
		Object.setPrototypeOf(this, new.target.prototype);
		this.name = WebApiException.name;
	}
}

export class ValidationException extends WebApiException {
	constructor(problemDetailsJson: string, statusCode: number) {
		super("Validation error.", problemDetailsJson, statusCode); // TODO: resource string
		Object.setPrototypeOf(this, new.target.prototype);
		this.name = WebApiException.name;
	}
}
