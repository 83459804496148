import { Icon } from "@skodaflow/web-library";
import { useIntl } from "react-intl";
import { Menu, MenuItem, Tooltip, IconButton } from "@mui/material";
import { get, map } from "lodash";
import { useCallback, useContext, useRef } from "react";
import { useToggle } from "../../hooks/useToggle";
import { LanguageContext } from "localization/components/Languages";
import { ApplicationContext } from "../../../infrastructure/contexts/contexts";
import { getCultureName } from "../../../localization/utils";
import { useActiveModule } from "../../../infrastructure/hooks/useActiveModule";

export function LanguageButton() {
	const intl = useIntl();
	const text = intl.formatMessage({ id: "language" });
	const [open, toggle] = useToggle(false);
	const anchorRef = useRef(null);

	const languages = useContext(LanguageContext);
	const {
		marvinConfiguration: { cultures },
	} = useContext(ApplicationContext);

	const activeModule = useActiveModule();

	// active language for current module or core language if no module is active
	const activeLanguage =
		(activeModule?.code ? get(languages.modules, activeModule?.code) : languages.core) ??
		languages.core;

	// select cultures for current module or all cultures from core
	const availableCultures =
		(activeModule?.code
			? map(activeModule?.cultures, (code) => ({
				code,
				name: getCultureName(code),
			  }))
			: undefined) ?? cultures;

	const changeLanguage = useCallback(
		(code: string) => {
			languages.onChange(code);
			toggle();
		},
		[languages.onChange]
	);

	return (
		<>
			<Tooltip title={text}>
				<IconButton aria-label={text} onClick={toggle} ref={anchorRef}>
					<Icon name="globe" fontSize="small" />
				</IconButton>
			</Tooltip>

			<Menu
				id="basic-menu"
				anchorEl={anchorRef?.current}
				open={open}
				onClose={toggle}
				MenuListProps={{ "aria-labelledby": "basic-button" }}
			>
				{map(availableCultures ?? cultures, (c) => (
					<MenuItem
						key={c.code}
						onClick={() => changeLanguage(c.code)}
						sx={c.code === activeLanguage ? { fontWeight: "bold" } : {}}
					>
						{c.name}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
