import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import {ApplicationContext} from "../../../infrastructure/contexts/contexts";
import {IClaim} from "../../../@marvin3/types/src";
import {findKey, includes} from "lodash";

type Props = {
    home: string;
    className?: string;
}

function makeLogoUrl(organization: string){
	return `/logo-${organization}.svg`;
}

type OrganizationProps = {
	organization: string,
	selected: string
}

function OrganizationLogo({organization, selected}: OrganizationProps){
	return <img height="16px" src={makeLogoUrl(organization)} alt="" hidden={organization !== selected} />
}

function useOrganization() {
	const {marvinConfiguration: { principal }} = React.useContext(ApplicationContext);

	return useMemo(() => {
		const claimOrganizationCode: string = principal?.claims?.find((claim: IClaim) => claim.type === "organizationCode")?.value ?? "";
		return findKey(configuration.organizationCodes, (values) => includes(values, claimOrganizationCode)) ?? "default";
	}, [principal]);
}

export function Logo({home, className}: Props) {
	const selected = useOrganization();

	return (
		<NavLink exact to={home} className={className} style={{display: "flex"}}>
			<OrganizationLogo organization="default" selected={selected} />
			<OrganizationLogo organization="skoda" selected={selected} />
			<OrganizationLogo organization="vw" selected={selected} />
		</NavLink>
	);
};
