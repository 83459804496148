import * as MarvinTypes from "@marvin3/types/src";
import {processRequest} from "../../@marvin3/types/src/features/api/utils/processRequest";
import {dispatchApiErrorEvent} from "../../@marvin3/types/src/features/api/utils/dispatchApiErrorEvent";

// TODO: puvodni fetchService je obsolete -> misto ni pouzivat fetchService2
// TODO: az se puvodni fetchService prestane pouzivat (vcetne modulu a emulatoru), zrusit ji a fetchServic2 prejmenovat na fetchService

enum RequestMethod {
	POST = "POST",
	GET = "GET",
	PUT = "PUT",
	DELETE = "DELETE"
}

enum StatusCode {
	Status200OK = 200,
	Status204NoContent = 204,
	Status400BadRequest = 400,
	Status401Unauthorized = 401,
	Status403Forbidden = 403,
	Status4040NotFound = 404,
	Status409Conflict = 409,
	Status500InternalServerError = 500
}

export enum ErrorTypes {
	unauthorized = "unauthorized",
	serverError = "serverError",
	validationError = "validationError"
}

export function createRequestInit(method: RequestMethod, headers?: Headers, body?: any): RequestInit {
	if (!method) {
		method = RequestMethod.GET;
	}

	headers = new Headers(headers);

	// pokud je zadano body, je ruzne od FormData a v headers neni uveden header 'Content-Type',
	// doplnime ho s hodnotou 'application/json' a body serializujeme do formatu JSON
	if (body && !(body instanceof FormData) && !headers.get("Content-Type")) {
		headers.set("Content-Type", "application/json");
		body = JSON.stringify(body);
	}

	return method === RequestMethod.GET
		? {
			method: method,
			headers: headers,
			credentials: "include"
		} : {
			method: method,
			headers: headers,
			body: body,
			credentials: "include"
		};
}

async function resolveFetch<TResult>(
	requestMethod: RequestMethod,
	url: string,
	headers?: Headers,
	body?: any
): Promise<TResult> {
	const init = processRequest(url, createRequestInit(requestMethod, headers, body))

	return processResponse(await fetch(url, init));
}

async function processResponse<TResult>(response: Response): Promise<TResult> {
	const responseText: string = await response.text();

	if (response.status === StatusCode.Status401Unauthorized) {
		dispatchApiErrorEvent({
			type: "https://tools.ietf.org/html/rfc7235#section-3.1",
			title: "Unauthorized",
			status: 401
		})
	}

	if (response.status === StatusCode.Status400BadRequest) {
		const parsed: any = JSON.parse(responseText); // TODO: doplnit typ
		Object.keys(parsed?.errors).forEach((key: string) => {
			const value: string = parsed?.errors[key];
			const event: CustomEvent<Object> = new CustomEvent("errorDispatcher", {
				detail: {
					id: key,
					type: ErrorTypes.validationError,
					message: `${key} - ${value}`
				},
			});
			document.dispatchEvent(event);
		});
	}

	if (response.status >= 500 && response.status <= 599) {
		const event = new CustomEvent("errorDispatcher", {
			detail: {
				id: Date.now(),
				type: ErrorTypes.serverError,
				message: `${responseText}${Date.now()}`
			},
		});
		document.dispatchEvent(event);

		return ([] as unknown) as TResult; // TODO: ???
	}

	if (responseText.length) {
		try {
			return JSON.parse(responseText) as TResult;
		} catch (ex) {
			throw new Error(`Server response is not in JSON format: ${responseText}`);
		}
	}

	return [] as any; // TODO: ???
}

/**
 * @deprecated use fetch functions from types/features/api
 */
export const fetchService: MarvinTypes.IFetchService = {
	get: <TResult>(url: string, headers?: Headers): Promise<TResult> => {
		return resolveFetch(RequestMethod.GET, url, headers);
	},

	post: <TResult>(url: string, body?: any, headers?: Headers): Promise<TResult> => {
		return resolveFetch(RequestMethod.POST, url, headers, body);
	},

	put: <TResult>(url: string, body?: any, headers?: Headers): Promise<TResult> => {
		return resolveFetch(RequestMethod.PUT, url, headers, body);
	},

	delete: <TResult>(url: string, headers?: Headers): Promise<TResult> => {
		return resolveFetch(RequestMethod.DELETE, url, headers);
	}
};
