import React, {useContext} from "react";
import {ApplicationContext} from "infrastructure/contexts/contexts";
import {map} from "lodash";
import {useActiveModule} from "infrastructure/hooks/useActiveModule";

export function useModuleItems() {
	const {marvinConfiguration: {modules}} = useContext(ApplicationContext);

	const module = useActiveModule();

	return React.useMemo(() => map(modules, ({name, code}) => ({
		name,
		code,
		active: code === module?.code
	})), [modules, module]);
}
