import {Button, ButtonProps} from "@mui/material";
import {useCallback} from "react";

type Props = Omit<ButtonProps, "onClick"> & {
    mail: string;
}

export function EmailButton({mail, ...props}: Props) {
	const handleEmailClick = useCallback(() => {
		window.location.href = `mailto:${mail}`;
	}, [mail]);

	return (
		<Button variant="contained" color="primary" onClick={handleEmailClick} {...props} />
	);
}
