import {FetchServiceOptions, ProblemDetails} from "../types";
import {dispatchApiErrorEvent} from "./dispatchApiErrorEvent";

/**
 * reads the response body as json and returns it as ProblemDetails,
 * or throws exception if the response is not a valid json
 */
async function processAsProblem(response: Response): Promise<ProblemDetails> {
	try {
		// 401 is not returning valid json, the response is just a nginx error page
		if(response.status === 401){
			return {
				type: "https://tools.ietf.org/html/rfc7235#section-3.1",
				title: "Unauthorized",
				status: 401,
			};
		}

		return await response.json()
	} catch (error: any) {
		return {
			type: error.type,
			status: response.status,
			title: response.statusText,
			detail: `Can't read response body as json due to '${"message" in error ? error.message : error}'`,
		} as ProblemDetails
	}
}

export function shouldHandle(problem: ProblemDetails, response?: Response, options?: FetchServiceOptions) {
	if(options?.omitProblems === undefined){
		return true;
	}

	if(typeof options?.omitProblems === "boolean"){
		return options?.omitProblems !== true;
	}

	if(typeof options?.omitProblems === "function"){
		return options.omitProblems(response);
	}

	// check whether the problem should be handled
	return problem.status && !options?.omitProblems?.includes(problem.status);
}

/**
 * function wraps the fetch response and throws exception if the response is not ok based on expected behavior of the API
 */
export async function processResponse(response: Response, options?: FetchServiceOptions): Promise<Response> {
	if (response.status >= 400) {
		const problem = await processAsProblem(response);

		// dispatch event if the problem is not in the list of omitted problems
		if (shouldHandle(problem, response, options)) {
			dispatchApiErrorEvent(problem);
		}

		// throw exception based on
		throw problem;
	}

	// causally read the response
	return response;
}
