import React from "react";
import {Button, ButtonVariants, Divider} from "@skodaflow/web-library";
import tokens from "@skodaflow/web-tokens/src/tokens.json"
import {filter, first, forEach, groupBy, map, orderBy, size} from "lodash";
import {NotificationItem} from "./Item/Item";
import {Box} from "@mui/material"
import {INotification} from "./types";
import {TranslatedText} from "../TranslatedText/TranslatedText";

type Props = {
    items?: INotification[];
    selectedId?: string;
    onSelect?: (id: string) => void;
    onDismiss?: (id: string) => void;
    system?: string;
}

export function Notifications({items, onSelect, onDismiss, system, selectedId}: Props) {
	const groups = React.useMemo(() =>
		orderBy(map(groupBy(filter(items, i => !i.dismissed), n => n.group), notifications => ({
			source: first(notifications)?.group ?? "",
			notifications
		})), g => system === g.source ? "1" : g.source)
	, [items, system]
	);

	const dismissAll = React.useCallback(() => {
		onDismiss && forEach(filter(items, i => !i.dismissed), notification => onDismiss(notification.id));
	}, [items, onDismiss])

	return (
		<Box
			sx={{
				width: {
					sx: "calc(100% - 30px)",
					md: "400px"
				},
				minHeight: "120px",
				maxHeight: "600px",
				background: tokens["flow-colors"]["surface-tertiary"],
				border: `1px solid ${tokens["flow-colors"]["surface-secondary"]}`,
				boxShadow: "0px 24px 64px rgba(0, 0, 0, 0.15)",
				borderRadius: "8px",
				display: "flex",
				flexDirection: "column",
				overflow: "hidden"
			}}
		>
			<Box sx={{display: "flex", alignItems: "center", marginBottom: "8px", padding: "17px 8px 8px 16px"}}>
				<div style={{flex: 1}}>
					<h4 style={{margin: 0}}><TranslatedText text="notifications"/></h4>
				</div>

				{size(groups) > 0 && onDismiss && (
					<Button compact variant={ButtonVariants.TERTIARY} onClick={dismissAll}>
						<TranslatedText text="dismiss-all"/>
					</Button>
				)}
			</Box>

			{size(groups) == 0 &&
                <div style={{flex: 1, overflow: "overlay"}}>
                	<p className='disclaimer' style={{marginLeft: "17px"}}>
                		<TranslatedText text="no-notifications"/>
                	</p>
                </div>
			}

			<Box sx={{
				flex: 1,
				overflowY: "auto",
				// disable custom scroll
				"::-webkit-scrollbar": {
					width: "0px"
				}
			}}>
				{map(groups, (group, index) => (
					<React.Fragment key={group.source}>
						{index !== 0 && <Divider/>}
						<p className='caption2' style={{marginLeft: "17px"}}>{group.source}</p>

						{map(group.notifications, (notification, index) => (
							<React.Fragment key={`notification-${notification.id}`}>
								{index !== 0 && <Divider/>}
								<NotificationItem
									key={`notification-${notification.id}`}
									onDismiss={onDismiss ? () => onDismiss(notification.id) : undefined}
									onSelect={onSelect ? () => onSelect(notification.id) : undefined}
									selected={selectedId === notification.id}
									highlighted={system === notification.group}
									{...notification}
								/>
							</React.Fragment>
						))}
					</React.Fragment>
				))}
			</Box>
		</Box>
	)
}
