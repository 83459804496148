import React from "react";
import {Box} from "@mui/material";
import {ModuleNode} from "../../Module/Node";

export function Workspace({children}: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<div
			style={{
				display: "flex",
				height: "100vh",
				flexDirection: "row",
				backgroundColor: "white",
				overflow: "hidden",
				flex: 1
			}}
		>
			<ModuleNode node="navigation" render={navigation => (
				<Box
					sx={{
						overflow: "auto",
						height: "100%",
						":empty": {
							display: "none"
						},
						".MuiScopedCssBaseline-root": {
							height: "100%"
						}
					}}
				>
					{navigation}
				</Box>
			)}/>
			<div
				style={{
					flex: 1,
					display: "flex",
					overflow: "auto"
				}}
			>
				{children}
			</div>
		</div>
	);
}
