export function resolveActiveClass(
	name: string,
	code: string,
	activeItems: { [key: string]: boolean }
) {
	return `mv-${name} ${activeItems[code] ? "active" : ""}`;
}

export enum MediaQueryVariables {
	extraSmall = "(min-width: 0px)",
	maxExtraSmall = "(max-width: 599px)",
	small = "(min-width: 600px)",
	maxSmall = "(max-width: 959px)",
	medium = "(min-width: 960px)",
	maxMedium = "(max-width: 1279px)",
	large = "(min-width: 1280px)",
	maxLarge = "(max-width: 1919px)",
	extraLarge = "(min-width: 1920px)",
}
