import {
	ApplicationInsights,
	SeverityLevel,
	IExceptionTelemetry,
	ITraceTelemetry,
} from "@microsoft/applicationinsights-web";
import { ILogger } from "@marvin3/types/src";

/* eslint no-console: "off" */

//Enum not working from types, so duplicated, when accessing to enum, value is always undefined
export enum LogLevel {
	Verbose = 0,
	Information = 1,
	Warning = 2,
	Error = 3,
	Critical = 4,
}

export class ApplicationInsightsLogger implements ILogger {
	private applicationInsights: ApplicationInsights;

	public constructor(applicationInsights: ApplicationInsights) {
		if (!applicationInsights) {
			throw new Error("Argument 'applicationInsights' is null or undefined."); // TODO: pripravit a pouzit AppGuard.argumentNotNullNorUndefined
		}

		this.applicationInsights = applicationInsights;
	}

	public exception(exception: Error, logLevel?: LogLevel): void {
		const telemetry: IExceptionTelemetry = {
			exception: exception,
			severityLevel: logLevel ? this.mapLogLevelToSeverityLevel(logLevel) : undefined,
		};

		this.applicationInsights.trackException(telemetry);
	}

	public trace(message: string, logLevel?: LogLevel): void {
		const telemetry: ITraceTelemetry = {
			message: message,
			severityLevel: logLevel ? this.mapLogLevelToSeverityLevel(logLevel) : undefined,
		};

		this.applicationInsights.trackTrace(telemetry);
	}

	public error(message: string): void {
		this.trace(message, LogLevel.Error);
	}

	public warning(message: string): void {
		this.trace(message, LogLevel.Warning);
	}

	public information(message: string): void {
		this.trace(message, LogLevel.Information);
	}

	private mapLogLevelToSeverityLevel(logLevel: LogLevel): SeverityLevel {
		switch (logLevel) {
			case LogLevel.Verbose:
				return SeverityLevel.Verbose;

			case LogLevel.Information:
				return SeverityLevel.Information;

			case LogLevel.Warning:
				return SeverityLevel.Warning;

			case LogLevel.Error:
				return SeverityLevel.Error;

			case LogLevel.Critical:
				return SeverityLevel.Critical;

			default:
				throw new Error(`Value ${logLevel} is out of range.`); // TODO: pripravit OutOfRangeException
		}
	}
}

export class ConsoleLogger implements ILogger {
	public exception(exception: Error, logLevel?: LogLevel): void {
		console.error(exception);
	}

	public trace(message: string, logLevel?: LogLevel): void {
		switch (logLevel) {
			case undefined:
			case LogLevel.Verbose:
			case LogLevel.Information:
				console.log(message);
				break;

			case LogLevel.Warning:
				console.warn(message);
				break;

			case LogLevel.Error:
			case LogLevel.Critical:
				console.error(message);
				break;

			default:
				throw new Error(`Value ${logLevel} is out of range.`); // TODO: pripravit OutOfRangeException
		}
	}

	public error(message: string): void {
		this.trace(message, LogLevel.Error);
	}

	public warning(message: string): void {
		this.trace(message, LogLevel.Warning);
	}

	public information(message: string): void {
		this.trace(message, LogLevel.Information);
	}
}

export class CompositeLogger implements ILogger {
	private loggers: ILogger[];

	public constructor(loggers: ILogger[]) {
		if (!loggers) {
			throw new Error("Argument 'loggers' is null or undefined."); // TODO: pripravit a pouzit AppGuard.argumentNotNullNorUndefined
		}

		this.loggers = loggers;
	}

	public exception(exception: Error, logLevel?: LogLevel): void {
		this.loggers.forEach((logger: ILogger) => logger.exception(exception, logLevel));
	}

	public trace(message: string, logLevel?: LogLevel): void {
		this.loggers.forEach((logger: ILogger) => logger.trace(message, logLevel));
	}

	public error(message: string): void {
		this.trace(message, LogLevel.Error);
	}

	public warning(message: string): void {
		this.trace(message, LogLevel.Warning);
	}

	public information(message: string): void {
		this.trace(message, LogLevel.Information);
	}
}
