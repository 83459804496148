import styled from "@emotion/styled";
import tokens from "@skodaflow/web-tokens/src/tokens.json";

export const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`

export const StyledFooter = styled.div`
  margin-top: auto;
  display: flex;
  gap: 2rem 4rem;
  flex-wrap: wrap;
  padding: 28px 96px 28px 96px;
  color: ${tokens["flow-colors"]["on-surface0"]};
  background-color: ${tokens["flow-colors"]["surface-quarternary"]};
`
