import {Indicator} from "@skodaflow/web-library";
import React from "react";

type Props = {
    recent?: boolean;
    title: string;
}

export function NotificationItemHeader({ recent, title }: Props) {
	const labelComponent = <span className='subheadline'>{title}</span>;

	if(!recent)
		return labelComponent;

	return (
		<Indicator color='neutral'>
			{labelComponent}
		</Indicator>
	);
}
