import React from "react";

export function useToggle(initialState = false): [boolean, () => void, () => void, () => void] {
	const [state, setState] = React.useState(initialState);
	const toggle = React.useCallback(() => {
		setState(s => !s)
	}, []);

	const open = React.useCallback(() => setState(true), []);
	const close = React.useCallback(() => setState(false), []);

	return [state, toggle, open, close];
}
