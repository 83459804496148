import * as MarvinTypes from "@marvin3/types/src";
import {IFetchService} from "@marvin3/types/src";
import {createRequestInit} from "./fetchService";
import {dispatchApiErrorEvent} from "../../@marvin3/types/src/features/api/utils/dispatchApiErrorEvent";

enum RequestMethod {
	POST = "POST",
	GET = "GET",
	PUT = "PUT",
	DELETE = "DELETE"
}

enum StatusCode {
	Status200OK = 200,
	Status204NoContent = 204,
	Status400BadRequest = 400,
	Status401Unauthorized = 401,
	Status403Forbidden = 403,
	Status4040NotFound = 404,
	Status409Conflict = 409,
	Status500InternalServerError = 500
}

async function resolveFetch<TResult>(
	methodType: RequestMethod,
	url: string,
	headers?: Headers,
	body?: any
): Promise<TResult> {
	const init: RequestInit = createRequestInit(methodType, headers, body);

	return processResponse(await fetch(url, init));
}

/**
 * @deprecated use fetch functions from types/features/api
 */
export const fetchService2: IFetchService = {
	get: <TResult>(url: string, headers?: Headers): Promise<TResult> => {
		return resolveFetch(RequestMethod.GET, url, headers);
	},

	post: <TResult>(url: string, body?: any, headers?: Headers): Promise<TResult> => {
		return resolveFetch(RequestMethod.POST, url, headers, body);
	},

	put: <TResult>(url: string, body?: any, headers?: Headers): Promise<TResult> => {
		return resolveFetch(RequestMethod.PUT, url, headers, body);
	},

	delete: <TResult>(url: string, headers?: Headers): Promise<TResult> => {
		return resolveFetch(RequestMethod.DELETE, url, headers);
	}
};

async function processResponse<TResult>(response: Response): Promise<TResult> {
	const responseText: string = await response.text();

	if (response.status === StatusCode.Status401Unauthorized) {
		if (response.status === StatusCode.Status401Unauthorized) {
			dispatchApiErrorEvent({
				type: "https://tools.ietf.org/html/rfc7235#section-3.1",
				title: "Unauthorized",
				status: 401
			})
		}
		throw new MarvinTypes.UnauthorizedException(responseText, response.status);
	}

	if (response.status === StatusCode.Status400BadRequest) {
		throw new MarvinTypes.ValidationException(responseText, response.status);
	}

	if (response.status >= 500 && response.status <= 599) {
		throw new MarvinTypes.WebApiException("Unknown server error.", responseText, response.status); // TODO: resource string
	}

	if (responseText.length) {
		try {
			return JSON.parse(responseText) as TResult;
		} catch (ex) {
			throw new Error(`Server response is not in JSON format: ${responseText}`);
		}
	}

	return null as any;
}
