import {PropsWithChildren} from "react";


export function Pre({children}: PropsWithChildren<any>) {
	return (
		<pre style={{
			borderTopWidth: "1px",
			borderRightWidth: "1px",
			borderBottomWidth: "1px",
			borderLeftWidth: "1px",
			borderTopStyle: "solid",
			borderRightStyle: "solid",
			borderBottomStyle: "solid",
			borderLeftStyle: "solid",
			borderTopColor: "rgb(158, 159, 160)",
			paddingTop: "8px",
			paddingBottom: "8px",
			paddingLeft: "16px",
			paddingRight: "16px",
			borderTopLeftRadius: "8px",
			borderTopRightRadius: "8px",
			borderBottomRightRadius: "8px",
			borderBottomLeftRadius: "8px",
			fontFamily: "monospace",
			fontSize: "11px",
			lineHeight: "18px",
			textAlign: "left",
			overflowX: "auto",
			overflowY: "auto",
		}}>
			{children}
		</pre>
	)
}
