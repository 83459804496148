import { errorHandlerContext } from "infrastructure/services/errorHandlerService";
import {
	serviceProvider,
	IServiceProvider,
	WellKnownServices,
} from "infrastructure/services/serviceProvider";
import { cssService } from "infrastructure/services/cssGeneratorService";
import { fetchService } from "infrastructure/services/fetchService";
import { fetchService2 } from "infrastructure/services/fetchService2";
import {
	CompositeLogger,
	ApplicationInsightsLogger,
	ConsoleLogger,
} from "infrastructure/services/loggers";
import { ApplicationInsights, ITelemetryPlugin } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory, History } from "history";

export const applicationHistory: History = createBrowserHistory();

const reactPlugin: ITelemetryPlugin = new ReactPlugin() as any; // TODO: doresit (ReactPlugin implementuje ITelemetryPlugin!, mozna je ale problem s verzemi NPM)
const applicationInsights: ApplicationInsights = new ApplicationInsights({
	config: {
		instrumentationKey: configuration.applicationInsightsInstrumentationKey,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: applicationHistory },
		},
	},
});
applicationInsights.loadAppInsights();

export class Application {
	private static _instance: Application;
	private readonly _serviceProvider: IServiceProvider;

	constructor() {
		this._serviceProvider = serviceProvider();
		this._serviceProvider.registerService("cssGeneratorService", cssService);
		this._serviceProvider.registerService(WellKnownServices.FetchService, fetchService);
		this._serviceProvider.registerService(WellKnownServices.FetchService2, fetchService2);
		this._serviceProvider.registerService("errorHandlerService", errorHandlerContext());
		this._serviceProvider.registerService(
			WellKnownServices.Logger,
			new CompositeLogger([new ConsoleLogger(), new ApplicationInsightsLogger(applicationInsights)])
		);
	}

	private static get instance(): Application {
		if (!Application._instance) {
			Application._instance = new Application();
		}

		return Application._instance;
	}

	public static get serviceProvider(): IServiceProvider {
		return Application.instance._serviceProvider;
	}
}
