import {useMediaQuery} from "@mui/material";
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/lab/Alert";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {MediaQueryVariables} from "../utils";

export enum AlertType {
	error = "error",
	success = "success",
	info = "info",
	warning = "warning",
}

interface INotificationProps extends AlertProps {
	title?: string;
	text?: string;
	isOpen?: boolean;
	type?: AlertType;
	autoHideDuration?: number;
	notificationId?: string;
}

export const Alert = (props: INotificationProps) => {
	const [open, setOpen] = React.useState(true);
	const [detailOpen, setDetailOpen] = React.useState(false);
	const extraSmallMatch = useMediaQuery(MediaQueryVariables.extraSmall);
	const maxSmall = useMediaQuery(MediaQueryVariables.maxExtraSmall);
	const isMobile = extraSmallMatch && maxSmall

	React.useEffect(() => {
		props.isOpen && setOpen(props.isOpen);
		return () => { };
	}, [props.isOpen, props.id]);

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") return;
		setOpen(false);

		if (props.onClose) {
			props.onClose(event as React.SyntheticEvent);
		}
	};

	return (
		<Snackbar
			data-component="mv-Alert"
			anchorOrigin={
				isMobile
					? { horizontal: "right", vertical: "bottom" }
					: { horizontal: "right", vertical: "top" }
			}
			style={isMobile ? { width: "100%" } : {}}
			onClose={handleClose}
			itemID={props.notificationId}
			id={props.notificationId}
			autoHideDuration={props.autoHideDuration || 15000}
			open={open}
		>
			<MuiAlert
				key={props.notificationId}
				className={detailOpen ? "mv-alert active" : "mv-alert"}
				action={
					<React.Fragment>
						{!detailOpen && <InfoIcon onClick={() => setDetailOpen(true)} className="infoIconAlert" />}
						<CloseIcon id={props.notificationId} onClick={handleClose} className="closeIconAlert" />
					</React.Fragment>
				}
				severity={props.type || AlertType.error}
				title={props.title}
			>
				<div className="mv-container">
					<div className="mv-header">
						<div className="mv-title">{props.title || "Error"}</div>
					</div>
					{detailOpen && <div className="mv-detail">{props.text}</div>}
				</div>
			</MuiAlert>
		</Snackbar>
	);
};
