import { fetchService } from "./fetchService";

export interface IIdentity {
	nameIdentifier: string;
	name: string;
	firstName: string;
	lastname: string;
	country: string;
}

async function logOutUser(): Promise<any> {
	localStorage.setItem("logout", "1");
	await fetchService.post("/logout");
	window.location.href = `${configuration.b2bPortalUrl}/pkmslogout`;
}

export { logOutUser };

