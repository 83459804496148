// TODO: presunout do @marivn3/types
export class WellKnownServices {
	public static Logger: string = "logger";
	public static FetchService: string = "fetchService"; // TODO: prejmenovat na legacyFetchService (vcetne emulatoru a modulu)
	public static FetchService2: string = "fetchService2"; // TODO: fetchService2 prejmenovat na fetchService
}

export interface IServiceProvider {
	registerService: (key: string, service: any) => void;
	getServiceByKey: (key: string) => any;
}

export function serviceProvider(): IServiceProvider {
	const store: { [key: string]: any } = {};

	function getServiceByKey(key: string) {
		return store[key];
	}

	function registerService(key: string, service: any) {
		store[key] = service;
	}

	return {
		registerService,
		getServiceByKey,
	};
}
