import {DependencyList, useCallback, useEffect, useState} from "react";

/**
 * hook that adds an event listener to the document and removes it when the component unmounts (or when the deps change)
 */
export function useDocumentEvent<T extends Event>(type: string, listener: (ev: T) => void, deps?: DependencyList, options?: boolean | AddEventListenerOptions) {
	useEffect(() => {
		// Add the event listener when the component mounts
		document.addEventListener(type, listener as any, options);

		// Remove the event listener when the component unmounts
		return () => {
			document.removeEventListener(type, listener as any);
		};
	}, [deps ?? []]);
}
