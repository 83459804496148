import React, {useContext, useMemo} from "react";
import {ProgressBar} from "@skodaflow/web-library";
import tokens from "@skodaflow/web-tokens/src/tokens.json"
import {NotificationItemHeader} from "./Header";
import {NotificationItemContainer} from "./Container";
import dayjs from "dayjs";
import {includes} from "lodash";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {INotification} from "../types";
import {ExternalNavigationLinkButton, NavigationLinkButton} from "../../../../@marvin3/types/src";
import {LanguageContext} from "../../../../localization/components/Languages";

type Props = INotification & {
    /**
     * callback on dismiss (if not passed, x button won't be shown)
     */
    onDismiss?: () => void;
    /**
     * callback on container click
     */
    onSelect?: () => void;

    /**
     * marker that notification is currently selected
     */
    selected?: boolean;

    highlighted?: boolean;

    sx?: SxProps<Theme>;
}

export function NotificationItem(props: React.PropsWithChildren<Props>) {
	const {
		title,
		description,
		onDismiss,
		onSelect,
		selected,
		recent,
		children,
		highlighted,
		linkUrl,
		linkText,
		created,
		progressPercentage,
		sx
	} = props;

	const externalLink = linkUrl && includes(linkUrl, "http");
	const internalLink = linkUrl && !externalLink;

	const { core } = useContext(LanguageContext);

	// due to set up of dayjs, we need to wrap date in memo based on core language (so the dayjs has enough time to react)
	const createdText = useMemo(() => dayjs.utc().to(created), [core, created]);

	return (
		<NotificationItemContainer onDismiss={onDismiss} selected={selected} onSelect={onSelect} sx={sx}
			highlighted={highlighted}>
			<NotificationItemHeader recent={recent} title={title}/>

			{description && (
				<p className='disclaimer' style={{
					margin: "4px 0px 4px 0px",
					paddingRight: "15px",
					overflowWrap: "break-word"
				}}>{description}</p>
			)}

			{progressPercentage && (
				<ProgressBar
					variant="spinner"
					loadingPercentageDescribeText={title}
					loadingPercentage={progressPercentage}
					successMessage={title}
					state={progressPercentage === 100 ? "success" : "loading"}
					spinnerControlledAnimation="controlled"
					style={{margin: "14px 0px 14px 0px"}}
				/>
			)}

			<p className='disclaimer' style={{
				color: tokens["flow-colors"]["on-surface500"],
				margin: "4px 0px 8px 0px"
			}}>{createdText}</p>

			{internalLink && linkText && (
				<NavigationLinkButton path={linkUrl} text={linkText} compact/>
			)}

			{externalLink && linkText && (
				<ExternalNavigationLinkButton url={linkUrl} text={linkText} compact/>
			)}

			{children}
		</NotificationItemContainer>
	)
}
