import {useMemo} from "react";
import dayjs from "dayjs";

// hint: unfortunately dayjs does not support dynamic imports (yet) this list is based on the expected supported locales
import("dayjs/locale/cs");
import("dayjs/locale/en");

// import plugins
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

// register plugins
dayjs.extend(relativeTime);
dayjs.extend(utc);

export function useDayJs(culture: string) {
	// using memo instead of effect so the culture is changed before first render
	useMemo(() => {
		const language = culture.split("-")[0];
		if(language !== dayjs.locale())
			dayjs.locale(language);
	}, [culture]);
}
