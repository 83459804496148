import { History } from "history";
import {ApplicationContext, IApplicationContext} from "infrastructure/contexts/contexts";
import React, {useContext, useEffect} from "react";
import {find, get} from "lodash";
import {marvinUITheme} from "infrastructure/contexts/deprecated";
import {LanguageContext} from "localization/components/Languages";
import {getCultureName} from "localization/utils";
import {applicationHistory} from "../../infrastructure/ApplicationCore";
import {useAsRef} from "../../hooks/useAsRef";
import {mountModule, unmountModule} from "./mount";

type Props = {
	/**
	 * module code
	 */
	code: string;

	history: History;
}

export function ModuleLoader({ code, history }: Props) {
	const context = useAsRef(useContext<IApplicationContext>(ApplicationContext));

	const languages = useContext(LanguageContext);

	// select module language
	const language = get(languages.modules, code, languages.core);

	useEffect(() => {
		const module = find(context.current.marvinConfiguration.modules, m => m.code?.toLowerCase() === code?.toLowerCase());

		if (!module) {
			console.error('Module not found');
			return;
		}

		let canceled = false;

		const moduleContext = {
			history: applicationHistory,
			onModuleLoad: context.current.onModuleLoad,
			getService: context.current.getService,
			configuration,
			moduleConfiguration: module,
			settings: { cultureCode: language },
			cultures: module.cultures.map((code, index) => ({
				code: code,
				isDefault: index === 0,
				name: getCultureName(code)
			})),
			// legacy stuff
			isMobile: false,
			concernThemeVariables: {},
			theme: marvinUITheme()
		};

		mountModule(code, moduleContext, () => canceled)
			.then(() => console.debug(`Module ${code} has been loaded`))
			.catch(() => console.error(`Failed to load module ${code}`));

		return () => {
			canceled = true;
			unmountModule(code);
		};
	}, [code, history, language]);

	// todo could use a nice loading indicator (while script and data are being is loading)
	return (
		<div id={code} style={{ flex: 1, overflow: "hidden", display: "flex" }} />
	);
}