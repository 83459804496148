import React from "react";
import {useHistory} from "react-router-dom";
import {Button, ButtonVariants} from "@skodaflow/web-library";

type Props = {
    text: string;
    path: string;
    compact?: boolean;
    icon?: string;
}

export function NavigationLinkButton({ text, path, compact, icon }: Props) {
	const { push } = useHistory();

	const navigate = React.useCallback(() => {
		push(path)
	}, [push, text]);

	return <Button icon={icon} compact={compact} variant={ButtonVariants.GHOST} label={text} onClick={navigate} />
}
