import {FetchServiceOptions, ProblemDetails} from "../types";
import {processResponse, shouldHandle} from "./processResponse";
import {processRequest} from "./processRequest";
import {dispatchApiErrorEvent} from "./dispatchApiErrorEvent";

/**
 * extension of fetch that adds default headers, credentials and common error handling
 */
export async function fetchService(input: RequestInfo | URL, init?: RequestInit, options?: FetchServiceOptions): Promise<Response> {
	const request = processRequest(input, init, options);

	let response: Response | undefined = undefined;
	try {
		response = await fetch(input, request)
	} catch (e) {
		const problem = {
			type: "network-error",
			title: "Network error",
			detail: e?.toString(),
		} as ProblemDetails;

		if (shouldHandle(problem, response, options)) {
			dispatchApiErrorEvent(problem);
		}

		throw problem;
	}

	return await processResponse(response, options);
}

export async function fetchJson<TResult>(input: RequestInfo | URL, init?: RequestInit, options?: FetchServiceOptions): Promise<TResult> {
	const response: Response | undefined = await fetchService(input, init, options);
	try {
		return await response.json();
	} catch (error: any) {
		const problem = {
			type: "invalid-json",
			title: "Invalid JSON",
			detail: error?.toString(),
		} as ProblemDetails;

		if (shouldHandle(problem, response, options)) {
			dispatchApiErrorEvent(problem);
		}

		throw problem;
	}
}

export async function fetchText<TResult>(input: RequestInfo | URL, init?: RequestInit, options?: FetchServiceOptions): Promise<string> {
	let response: Response | undefined = undefined;
	try {
		response = await fetchService(input, init, options);
		return await response.text();
	} catch (error: any) {
		const problem =  {
			type: "invalid-text",
			title: "Invalid text",
			detail: error?.toString(),
		} as ProblemDetails;

		if (shouldHandle(problem, response, options)) {
			dispatchApiErrorEvent(problem);
		}

		throw problem;
	}
}
