import React from "react";
import {FormattedMessage} from "react-intl";
import {ButtonVariants, Modal} from "@skodaflow/web-library";

type Props = {
	open?: boolean;
	onClose: () => void;
}

export function UnauthorizedPopup({ open, onClose }: Props) {
	return (
		<Modal
			open={!!open}
			size="small"
			firstButtonVariant={ButtonVariants.PRIMARY}
			firstButton="Reload"
			onClickTopButton={() => window.location.reload()}
			secondButtonVariant={ButtonVariants.SECONDARY}
			secondButton="Cancel"
			secondButtonVisible
			onClickBottomButton={onClose}
		>
			<h3>
				<FormattedMessage id="unauthorizedTitle" />
			</h3>
			<p className="body">
				<FormattedMessage id="unauthorized"/>
			</p>
		</Modal>
	)
}
