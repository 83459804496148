import React from "react";
import { Icon } from "@skodaflow/web-library";
import { some } from "lodash";
import { Badge, ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { Notifications } from "../Notification/Notifications";
import tokens from "@skodaflow/web-tokens/src/tokens.json";
import { useToggle } from "../../hooks/useToggle";
import { useNotificationItems } from "./hooks/useNotificationItems";
import { useIntl } from "react-intl";

export function NotificationButton() {
	const intl = useIntl();
	const [open, toggle, , close] = useToggle(false);
	const [items, dismiss, seen, system] = useNotificationItems();

	const recent = React.useMemo(() => some(items, (n) => n.recent), [items]);
	const unread = React.useMemo(() => some(items, (n) => !n.dismissed), [items]);
	const text = intl.formatMessage({ id: "notifications" });

	return (
		<>
			<Badge
				variant="dot"
				overlap="circular"
				sx={{
					"& .MuiBadge-badge": {
						backgroundColor: recent
							? tokens["flow-colors"]["semantic-alert"]
							: tokens["flow-colors"]["on-surface300"],
						width: 12,
						height: 12,
						borderRadius: "50%",
						right: "17px",
						top: "14px",
						opacity: recent || unread ? 100 : 0,
					},
				}}
			>
				<Tooltip title={text}>
					<IconButton aria-label={text} onClick={toggle}>
						<Icon name="bell" fontSize="small" />
					</IconButton>
				</Tooltip>
			</Badge>

			{open && (
				<ClickAwayListener onClickAway={close}>
					<div
						style={{
							position: "absolute",
							top: "80px",
							right: "15px",
							marginLeft: "15px",
							zIndex: 200,
						}}
					>
						<Notifications items={items} onDismiss={dismiss} onSelect={seen} system={system} />
					</div>
				</ClickAwayListener>
			)}
		</>
	);
}
