import {ProgressBar, ProgressBarProps} from "@skodaflow/web-library";
import {HTMLAttributes} from "react";

export function Spinner(props: Omit<ProgressBarProps, keyof HTMLAttributes<HTMLDivElement> | "variant">){
	return (
		<div style={{margin: "auto", display: "flex", alignItems: "center", justifyContent: "center"}}>
			<ProgressBar variant="spinner" {...props} />
		</div>
	)
}
