import React from "react";
import { FormattedMessage } from "react-intl";

interface TranslatedTextProps {
	text: string | undefined;
}

export const TranslatedText = (props: TranslatedTextProps) => {
	return <FormattedMessage defaultMessage={`{${props.text}}`} id={props.text} />;
};
