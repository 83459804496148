import React from "react";
import { IError, IErrorHandlerServiceContext, IErrorHandlerService } from "@marvin3/types/src";

interface IErrorsState {
	errors: IError[];
}

interface IAction {
	type: string;
	payload: IError[] | IError | any;
}

const errorHandlerContext = (): IErrorHandlerServiceContext => {
	const ErrorHandlerContext: React.Context<IErrorHandlerService> = React.createContext<IErrorHandlerService>({
		errors: [],
		setError: () => { },
		removeError: () => { },
	});

	const { Provider, Consumer } = ErrorHandlerContext;

	function ErrorHandlerProvider(props: any) {
		return <Provider value={ErrorHandlerService()}>{props.children}</Provider>;
	}

	return {
		Provider: ErrorHandlerProvider,
		Consumer: Consumer,
	};
};

const initialState: IErrorsState = {
	errors: [],
};

const reducer = (state: IErrorsState, action: IAction): any => {
	if (action.type === "setError") {
		const error = action.payload;
		const data = state?.errors;
		data.push(error);
		return {
			errors: data,
		};
	} else if (action.type === "delete") {
		const data = state?.errors;
		data.shift();
		return {
			errors: data,
		};
	}
};

export function ErrorHandlerService(): IErrorHandlerService {
	const [state, dispatch] = React.useReducer(reducer, initialState);

	React.useEffect(() => {
		document.addEventListener("errorDispatcher", setError);
		document.addEventListener("removeError", removeError);
		return () => {
			document.removeEventListener("errorDispatcher", setError);
			document.removeEventListener("removeError", removeError);
		};
	}, []);

	function setError(item: Event): any {
		const event = item as CustomEvent;
		dispatch({ type: "setError", payload: event.detail });

		//if (event.detail.type !== ErrorTypes.unauthorized) {
		//	setTimeout(() => {
		//		dispatch({ type: "delete", payload: null });
		//	}, 10000);
		//}
	}

	function removeError(item?: Event) {
		dispatch({ type: "delete", payload: item });
	}

	return {
		errors: state.errors,
		setError,
		removeError,
	};
}

export { errorHandlerContext };
