import {Spinner} from "./Spinner";
import {Box} from "@mui/material";
import {PropsWithChildren} from "react";


type Props = PropsWithChildren<{
    loading?: boolean;
}>;

export function LoadingBox({children, loading}: Props) {
	return (
		<Box display="flex" flexDirection="column" flex="1" gap='1rem'>
			{loading ? <Spinner /> : <>{children}</>}
		</Box>
	)
};
