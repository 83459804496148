import {IApplicationContext} from "../../infrastructure/contexts/contexts";
import {LanguagesState} from "../components/Languages";
import {GetStoredLanguages} from "./getStoredLanguages";
import {IClaim, IMarvinConfiguration, IModuleConfiguration} from "@marvin3/types/src";
import {find, first, includes, map, reduce, some} from "lodash";

function resolveModuleLanguage(module: IModuleConfiguration, preference: string | undefined, principalPreference: string | undefined, browserPreference: string | undefined, defaultCulture: string): string {
	// preference is still available, use it
	if (preference && includes(module.cultures, preference))
		return preference;

	// try to match user profile
	if (principalPreference) {
		const language = find(module.cultures, c => c?.startsWith(principalPreference));

		if (language)
			return language;
	}

	// try to match browser
	const browserPreferenceLanguage = browserPreference && find(module.cultures, c => c?.startsWith(browserPreference));
	if (browserPreferenceLanguage) {
		return browserPreferenceLanguage;
	}

	// return first culture from module (default) or overall default culture
	return first(module.cultures) ?? defaultCulture;
}

function resolveCoreLanguage(configuration: IMarvinConfiguration, activeModulePreference: string | undefined, preference: string | undefined, principalPreference: string | undefined, browserPreference: string | undefined, defaultCulture: string) {
	// active module language is available in core, use it
	if (activeModulePreference && some(configuration.cultures, c => c.code === activeModulePreference))
		return activeModulePreference;

	// active module language is available in core, use it
	if (preference && some(configuration.cultures, c => c.code === preference))
		return preference;

	// principal's language is available in core, use it
	if (principalPreference) {
		const language = find(configuration.cultures, c => c.code?.startsWith(principalPreference));

		if (language)
			return language.code;
	}

	// browser's language is available in core, use it
	const browserPreferenceLanguage = browserPreference && find(configuration.cultures, c => c.code?.startsWith(browserPreference));
	if (browserPreferenceLanguage)
		return browserPreferenceLanguage.code;

	return defaultCulture;
}

export function resolveLanguages(activeModule: IModuleConfiguration | undefined, configuration: IApplicationContext["marvinConfiguration"], preferences?: GetStoredLanguages): LanguagesState {
	const defaultCulture = (find(configuration.cultures, c => c.default === true) ?? first(configuration.cultures))?.code;

	if (!defaultCulture)
		throw new Error("No cultures defined in configuration");

	// preference based on browser
	const browserPreference = navigator.language;

	// preference based on logged user (hint: it only contains first two letters of language code)
	const principalPreference = configuration.principal?.claims?.find((c: IClaim) => c.type === "preferredLanguage")?.value?.toLowerCase();

	// resolve modules languages and reduce them to a module code based object
	const modules: LanguagesState["modules"] = reduce(
		map(configuration.modules, module => ({
			[module.code]: resolveModuleLanguage(module, preferences?.modules[module.code], principalPreference, browserPreference, defaultCulture)
		})),
		(acc, module) => ({...acc, ...module}),
		{}
	);

	// resolve core language
	const core = resolveCoreLanguage(configuration, activeModule ? modules[activeModule.code] : undefined, preferences?.core, principalPreference, browserPreference, defaultCulture);

	return {core, modules}
}
