import React from "react";

type Props = {
    backgroundColor: string;
    environmentName: string;
}

export function EnvironmentBar({ backgroundColor, environmentName }: Props): JSX.Element {
	return (
		<div
			style={{
				backgroundColor,
				width: "30px",
				textTransform: "uppercase",
				display: "inline-block",
				height: "100%",
				zIndex: 999,
				color: "white"
			}}
		>
			<div
				style={{
					whiteSpace: "nowrap",
					WebkitTransform: "rotate(-90deg) translate(-100%, 0)",
					MozTransformOrigin: "rotate(-90deg) translate(-100%, 0)",
					filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
					transform: "rotate(-90deg) translate(-100%, 0)",
					display: "inline-block",
					transformOrigin: "0 0",
					textAlign: "right",
					fontSize: "22px",
					fontWeight: "bold",
					paddingRight: "15px",
					lineHeight: "30px"
				}}
			>
				{environmentName}
			</div>
		</div>
	);
}
