import React from "react";
import { IApplicationContext, ApplicationContext } from "infrastructure/contexts/contexts";
import { IClaim } from "@marvin3/types/src";

import { TranslatedText } from "components";
import {includes, last, map, split} from "lodash";
import {Box, Tooltip} from "@mui/material";
import {Header} from "./styled";
import "./table.css";
import {Icon} from "@skodaflow/web-library";

export default function ProfilePage(): JSX.Element {
	const context: IApplicationContext = React.useContext(ApplicationContext);

	const claims: IClaim[] = context.marvinConfiguration.principal?.claims?.sort((a: IClaim, b: IClaim) => a.type.localeCompare(b.type)) ?? [];

	return (
		<Box display="flex" flexDirection="column" sx={{ overflowWrap: "anywhere"}} paddingBottom="2rem">
			<h3><TranslatedText text="userInfo" /></h3>
			<table>
				<tr>
					<Header><TranslatedText text="type" /></Header>
					<Header><TranslatedText text="value" /></Header>
				</tr>
				{map(claims, (claim, index) => {

					return (
						<tr key={index}>
							<td>
								<Box display="flex" alignItems="center">
									{last(split(claim.type, "/"))}
									{includes(claim.type, "/") && <Tooltip title={claim.type}><Icon fontSize="small" className="circle-information" style={{ marginLeft: "auto"}} /></Tooltip>}
								</Box>
							</td>
							<td>
								<b>{claim.value}</b>
							</td>
						</tr>
					)
				})}
			</table>
			<h3><TranslatedText text="availableModules" /></h3>
			<table>
				<tr>
					<Header><TranslatedText text="name" /></Header>
					<Header><TranslatedText text="code" /></Header>
					<Header><TranslatedText text="version" /></Header>
				</tr>
				{map(context.marvinConfiguration.modules, (module, index) => (
					<tr key={module.code}>
						<td>{module.name}</td>
						<td>{module.code}</td>
						<td>{module.version}</td>
					</tr>
				))}
			</table>
		</Box>
	)
}
