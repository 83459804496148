import {Box, Paper} from "@mui/material";
import {PropsWithChildren} from "react";
import {Property} from "csstype";

export interface Props {
	header: string,
	direction?: Property.FlexDirection | undefined
	gap?: Property.Gap | undefined
}

function Outlined({ header, direction = "row", gap = "4rem", children }: PropsWithChildren<Props>) {

	return (
		<Paper variant='outlined'>
			<Box display="flex" flexDirection="column" gap="2rem" padding="2rem">
				<Box display="flex" className="caption1" justifyContent="center">{header}</Box>
				<Box display="flex" flexDirection={direction} gap={gap} justifyContent="center">
					{children}
				</Box>
			</Box>
		</Paper>
	)
}


export { Outlined }
