import React from "react";
import {Application} from "infrastructure/ApplicationCore";
import {IConfiguration, IMarvinConfiguration, IModulePartsRenderer} from "@marvin3/types/src";
import {ProblemDetails} from "../../@marvin3/types/src/features/api";
import {getCultureName} from "../../localization/utils";

export interface IApplicationContext {
    location: Location;

    /**
     * application configuration (environment, core service url, etc.)
     */
    configuration: IConfiguration;

    /**
     * marvin configuration (cultures, modules, etc.) based on current user
     */
    marvinConfiguration: IMarvinConfiguration;

	/**
	 * load core provided service
	 * todo refactor this in future using exported functions from @marvin3 types
	 */
	getService<TService>(key: string): TService;

	/**
	 * place to store all the modules nodes
	 */
	modules: { [code: string]: IModulePartsRenderer };

	/**
	 * stores loaded module components
	 * @param data
	 */
	onModuleLoad: (moduleParts: IModulePartsRenderer) => void;

	/**
	 * indicates if marvin configuration is still loading
	 */
	loading?: boolean;

	/**
	 * error that occurred during marvin configuration loading (critical application error)
	 */
	error?: ProblemDetails | null;
}

export const defaultMarvinConfiguration: IMarvinConfiguration = {
	principal: undefined,
	modules: [],
	/**
	 * default configuration of cultures (if not available from server)
	 */
	cultures: [
		{
			code: "en-GB",
			name: getCultureName("en-GB"),
			default: true
		},
		{
			code: "cs-CZ",
			name: getCultureName("cs-CZ")
		}
	]
}

export const defaultApplicationContext: IApplicationContext = {
	location: window.location,
	getService: Application.serviceProvider.getServiceByKey,
	configuration: configuration,
	marvinConfiguration: defaultMarvinConfiguration,
	modules: {},
	onModuleLoad(): void {
		throw new Error("Application context not initialized");
	},
};

export const ApplicationContext = React.createContext<IApplicationContext>(defaultApplicationContext);
