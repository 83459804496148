import React from "react";
import {Alert} from "../ui/Alert/Alert";
import {Application} from "infrastructure/ApplicationCore";
import {UnauthorizedPopup} from "../Authentication/UnauthorizedPopup/UnauthorizedPopup";
import {ErrorTypes} from "infrastructure/services/fetchService";
import {IError, IErrorHandlerService} from "@marvin3/types/src";

/**
 * @deprecated
 * Legacy error boundary component that should be replaced completely by ErrorBoundary component
 * Currently, is not possible due to usage of error messages.
 */
export class LegacyErrorBoundary extends React.Component<any,any> {
	private renderAlertIfNeeded(errors: IError[]): React.ReactNode {
		if (
			errors &&
            errors.length > 0 &&
            errors.find((error: IError) => error.type !== ErrorTypes.unauthorized)
		) {
			return <Alert title="ERROR" text={errors[0].message} id={new Date().toString()} isOpen/>;
		}
		return null;
	}

	private renderUnauthorizedAlertIfNeeded(errors: IError[]): React.ReactNode {
		if (
			errors &&
            errors.length > 0 &&
            errors.find((error: IError) => error.type === ErrorTypes.unauthorized)
		) {
			return (
				<UnauthorizedPopup onClose={() => {
					document.dispatchEvent(new CustomEvent("removeError", {}));
				}}/>
			);
		}

		return null;
	}

	render() {
		const {Provider, Consumer} = Application.serviceProvider.getServiceByKey(
			"errorHandlerService"
		);
		return (
			<Provider>
				<Consumer>
					{(provider: IErrorHandlerService) => (
						<React.Fragment>
							{this.renderAlertIfNeeded(provider.errors)}
							{this.renderUnauthorizedAlertIfNeeded(provider.errors)}
							{this.props.children}
						</React.Fragment>
					)}
				</Consumer>
			</Provider>
		);
	}
}
