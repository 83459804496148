import React from "react";

type State = [
    open: boolean,
    onOpen: () => void,
    onClose: () => void,
    setOpen: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
];

export function useSwitch(initialState = false): State {
	const [open, setOpen] = React.useState(initialState);
	const onOpen = React.useCallback(() => setOpen(true), []);
	const onClose = React.useCallback(() => setOpen(false), []);
	return [open, onOpen, onClose, setOpen]
}
